import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./styles.css";
import logo from "../../Images/icons/logo.png";
import NotificationDrawer from "../Drawer/NotificationDrawer";
import profilePic from "../../Images/icons/profile-pic.png";
import flagIcon from "../../Images/image/flagIcon.png";
import dataProvider from "../../Images/image/dataProvider.png";
import settingIcon from "../../Images/icons/settings.png";
import {
  FaCompany,
  FaGavel,
  FaLogout,
  FaPlan,
  FaPlus,
  FaTranslate,
  FaUser,
  FaRole,
  FaSlides,
  FaCommission,
  FaVerificationPending,
  FaEditVehicle,
  FaAds,
  FaInvoice,
  FaImport,
  FaLegal,
  FaFaq,
  FaAdsIcon,
  FaBidsIcon,
  FaPartnersIcon,
  FaContact,
  FaMessanger,
  FaFireIcon,
  FaCreditHistoryIcon,
  FaCancelReservationIcon,
  FaForumTagsIcon,
  FaForumFlagsIcon,
  FaVehicleReservationHistory,
  FaGeneralSettingIcon,
  FaCustomNotification,
  FaChatLogo,
} from "../../Images/icon";
import styled from "styled-components";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import { userTypeName } from "../../../config/constant";

const permission = JSON.parse(localStorage.getItem("permissions")) || [];

//Module Access
const auctionAccess = permission.find((o) => o.slug === "auction_event");
const classicAdsAccess = permission.find((o) => o.slug === "classic_ads");
const bidsAccess = permission.find((o) => o.slug === "bid_list");
const featuresAccess = permission.find((o) => o.slug === "features_management");
const roleAccess = permission.find((o) => o.slug === "role_management");
const usersAccess = permission.find((o) => o.slug === "users");
const verifyUser = permission.find((o) => o.slug === "verify_user");
const validateUser = permission.find((o) => o.slug === "verify_nomad_user");
const tanslationAccess = permission.find((o) => o.slug === "translation");
const planAccess = permission.find((o) => o.slug === "plans");
const couponAccess = permission.find((o) => o.slug === "coupons");
const taxRateAccess = permission.find((o) => o.slug === "tax_rates");
const commissionAccess = permission.find(
  (o) => o.slug === "commission_management"
);
const slideAccess = permission.find((o) => o.slug === "slide_management");
const companyAccess = permission.find((o) => o.slug === "manage_subsidiary");
const vehicleManagementAccess = permission.find(
  (o) => o.slug === "vehicle_management"
);
const invoiceAccess = permission.find((o) => o.slug === "invoices");
const logAccess = permission.find((o) => o.slug === "logs");
const legalDocumentsAccess = permission.find(
  (o) => o.slug === "legal_documents"
);
const faqAccess = permission.find((o) => o.slug === "faq");
const partnerAccess = permission.find((o) => o.slug === "partner");
const importedDealer = permission.find((o) => o.slug === "imported_dealer");
const contactUs = permission.find((o) => o.slug === "contact_us");
const chats = permission.find((o) => o.slug === "chats");
const hotdealAccess = permission.find((o) => o.slug === "hotdeal_event");
const creditAccess = permission.find((o) => o.slug === "credits");
const ForumAccess = permission.find((o) => o.slug === "forum");
const hotdealVehicleAccess = permission.find(
  (o) => o.slug === "hotdeal_vehicle_action"
);

const customNotifications = permission.find(
  (o) => o.slug === "custom_notifications"
);
const chatLimiter = permission.find((o) => o.slug === "chat_messages");

const Menu = (props) => {
  const [user, _user] = useState("");
  const [userDP, _userDP] = useState("");

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    if (!!data) {
      if (data.first_name || data.last_name) {
        _user(
          `${data.first_name !== null ? data.first_name : ""} ${
            data.last_name !== null ? data.last_name : ""
          }`
        );
      } else {
        _user(data.pseudo);
      }
      _userDP(data.profile_picture_url);
    }
  }, []);

  useEffect(() => {
    if (props.priceMenu) {
      let elemHeight = document
        .getElementById("pricingBtn")
        .getBoundingClientRect().top;
      let element = document.getElementById("pricingMenu");
      element.style.top = elemHeight - 42 + "px";
    }
  }, [props.priceMenu]);

  useEffect(() => {
    if (props.termsMenu) {
      let elemHeight = document
        .getElementById("termsBtn")
        .getBoundingClientRect().top;
      let element = document.getElementById("termsMenu");
      element.style.top = elemHeight - 100 + "px";
    }
  }, [props.termsMenu]);

  useEffect(() => {
    if (props.settingMenu) {
      let elemHeight = document
        .getElementById("settingBtn")
        .getBoundingClientRect().top;
      let element = document.getElementById("settingMenu");
      element.style.top = elemHeight - 10 + "px";
    }
  }, [props.settingMenu]);

  useEffect(() => {
    if (props.hotdealsMenu) {
      let elemHeight = document
        .getElementById("hotdealsBtn")
        .getBoundingClientRect().top;
      let element = document.getElementById("hotdealsMenu");
      element.style.top = elemHeight - (hotdealVehicleAccess ? 70 : 30) + "px";
    }
  }, [props.hotdealsMenu]);

  const closeMenu = () => {
    if (props.profileMenu) {
      props._profileMenu(false);
    }
    if (props.priceMenu) {
      props._priceMenu(false);
    }
    if (props.auctionMenu) {
      props._auctionMenu(false);
    }
    if (props.hotdealsMenu) {
      props._hotdealsMenu(false);
    }
    if (props.termsMenu) {
      props._termsMenu(false);
    }
    if (props.forumMenu) {
      props._forumMenu(false);
    }
    if (props.settingMenu) {
      props._settingMenu(false);
    }
  };

  const logout = () => {
    const device_id = JSON.parse(localStorage.getItem("deviceToken"));
    API.get(APP_URLS.LOGOUT + `?device_id=${device_id}&app_type=1`)
      .then((res) => {
        const resp = res.data;
        if (resp) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <div className="menuContainer">
      <div>
        <div className="logo">
          <NavLink to="/">
            <img src={logo} />
          </NavLink>
        </div>
        <div className="navigationContainer">
          {/* Auctions */}
          {(auctionAccess &&
            auctionAccess?.permissions?.find((o) => o.slug === "read")) ||
          (bidsAccess &&
            bidsAccess?.permissions?.find((o) => o.slug === "read")) ? (
            <Tooltip title="Auctions" placement="right">
              <DivWrapper className="footerLinks">
                <FaGavel
                  onClick={() => {
                    props._auctionMenu(!props.auctionMenu);
                    props._termsMenu(false);
                    props._profileMenu(false);
                    props._priceMenu(false);
                    props._hotdealsMenu(false);
                    props._forumMenu(false);
                    props._settingMenu(false);
                  }}
                />
              </DivWrapper>
            </Tooltip>
          ) : (
            ""
          )}
          {props.auctionMenu ? (
            <div className="auctionMenu">
              {auctionAccess &&
              auctionAccess?.permissions?.find((o) => o.slug === "read") ? (
                <>
                  <NavLink
                    to="/auction-events"
                    className="auctionLinks"
                    onClick={closeMenu}
                  >
                    <p className="py-2 ps-2 auctionEvent">Auction Events</p>
                  </NavLink>
                  <NavLink
                    to="/auctions"
                    className="auctionLinks"
                    onClick={closeMenu}
                  >
                    <p className="py-2 ps-2 auctionEvent">Auctions</p>
                  </NavLink>
                </>
              ) : null}
              {bidsAccess &&
              bidsAccess?.permissions?.find((o) => o.slug === "read") ? (
                <NavLink
                  to="/bid-list"
                  className="auctionLinks"
                  onClick={closeMenu}
                >
                  <p className="py-2 ps-2">Bids</p>
                </NavLink>
              ) : null}
            </div>
          ) : (
            ""
          )}

          {/* Hotdeals */}
          {hotdealAccess || hotdealVehicleAccess ? (
            <>
              <DivWrapper className="footerLinks" id="hotdealsBtn">
                <Tooltip
                  title="Hotdeal Events, Hotdeals, Cancel Reservation and Vehicle Reservation History"
                  placement="right"
                >
                  <FaFireIcon
                    onClick={() => {
                      props._hotdealsMenu(!props.hotdealsMenu);
                      props._auctionMenu(false);
                      props._termsMenu(false);
                      props._profileMenu(false);
                      props._priceMenu(false);
                      props._forumMenu(false);
                      props._settingMenu(false);
                    }}
                  />
                </Tooltip>
                {props.hotdealsMenu ? (
                  <div className="hotdealsMenu" id="hotdealsMenu">
                    {hotdealAccess &&
                    hotdealAccess?.permissions?.find(
                      (o) => o.slug === "read"
                    ) ? (
                      <>
                        <NavLink
                          to="/hotdeal-events"
                          className="auctionLinks"
                          onClick={closeMenu}
                        >
                          <p className="py-2 ps-2 auctionEvent">
                            Hotdeal Events
                          </p>
                        </NavLink>
                        <NavLink
                          to="/hotdeals"
                          className="auctionLinks"
                          onClick={closeMenu}
                        >
                          <p
                            className={`py-2 ps-2 ${
                              hotdealVehicleAccess ? "auctionEvent" : ""
                            }`}
                          >
                            Hotdeals
                          </p>
                        </NavLink>
                      </>
                    ) : (
                      ""
                    )}
                    {hotdealVehicleAccess &&
                    hotdealVehicleAccess?.permissions?.find(
                      (o) => o.slug === "read"
                    ) ? (
                      <>
                        <NavLink
                          to="/cancel-reservation-request"
                          className="auctionLinks"
                          onClick={closeMenu}
                        >
                          <p className="py-2 ps-2 auctionEvent">
                            Cancel Reservation
                          </p>
                        </NavLink>
                        <NavLink
                          to="/vehicle-reservation-history"
                          className="auctionLinks"
                          onClick={closeMenu}
                        >
                          <p className="py-2 ps-2">
                            Vehicle Reservation History
                          </p>
                        </NavLink>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </DivWrapper>
            </>
          ) : (
            ""
          )}

          {/* Classic Ads */}
          {classicAdsAccess &&
          classicAdsAccess?.permissions?.find((o) => o.slug === "read") ? (
            <Tooltip title="Classic Ads" placement="right">
              <LinkWrapper
                to="/classic-ads"
                className="links"
                onClick={closeMenu}
              >
                <FaAdsIcon />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Pending Verification */}
          {(verifyUser &&
            verifyUser?.permissions?.find((o) => o.slug === "read")) ||
          (validateUser &&
            validateUser?.permissions?.find((o) => o.slug === "read")) ? (
            <Tooltip title="Pending Verification " placement="right">
              <LinkWrapper
                to="/pending-verifications"
                className="links"
                onClick={closeMenu}
              >
                <FaVerificationPending />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Users */}
          {usersAccess &&
          usersAccess?.permissions?.find((o) => o.slug === "read") ? (
            <Tooltip title="Users" placement="right">
              <LinkWrapper to="/users" className="links" onClick={closeMenu}>
                <FaUser />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* companies */}
          {companyAccess &&
          companyAccess?.permissions?.find((o) => o.slug === "read") ? (
            <Tooltip title={userTypeName.DEALER} placement="right">
              <LinkWrapper
                to="/companies"
                className="links"
                onClick={closeMenu}
              >
                <FaCompany />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Plans, Coupons, Taxes and Credits */}
          {couponAccess || planAccess || taxRateAccess || creditAccess ? (
            <>
              <DivWrapper className="footerLinks" id="pricingBtn">
                <Tooltip
                  title="Plans, Coupons, Taxes and Credits"
                  placement="right"
                >
                  <FaPlan
                    onClick={() => {
                      props._priceMenu(!props.priceMenu);
                      props._termsMenu(false);
                      props._auctionMenu(false);
                      props._profileMenu(false);
                      props._forumMenu(false);
                      props._settingMenu(false);
                      props._hotdealsMenu(false);
                    }}
                  />
                </Tooltip>
                {props.priceMenu ? (
                  <div className="priceMenu" id="pricingMenu">
                    {planAccess &&
                    planAccess?.permissions?.find((o) => o.slug === "read") ? (
                      <NavLink
                        to="/plans"
                        className="auctionLinks"
                        onClick={closeMenu}
                      >
                        <p className="py-2 ps-2 auctionEvent">Plans</p>
                      </NavLink>
                    ) : (
                      ""
                    )}
                    {taxRateAccess &&
                    taxRateAccess?.permissions?.find(
                      (o) => o.slug === "read"
                    ) ? (
                      <NavLink
                        to="/tax-rates"
                        className="auctionLinks"
                        onClick={closeMenu}
                      >
                        <p className="py-2 ps-2 auctionEvent">Tax Rates</p>
                      </NavLink>
                    ) : (
                      ""
                    )}
                    {couponAccess &&
                    couponAccess?.permissions?.find(
                      (o) => o.slug === "read"
                    ) ? (
                      <NavLink
                        to="/coupons"
                        className="auctionLinks"
                        onClick={closeMenu}
                      >
                        <p className="py-2 ps-2 auctionEvent">Coupons</p>
                      </NavLink>
                    ) : (
                      ""
                    )}
                    {creditAccess &&
                    creditAccess?.permissions?.find(
                      (o) => o.slug === "read"
                    ) ? (
                      <NavLink
                        to="/credits"
                        className="auctionLinks"
                        onClick={closeMenu}
                      >
                        <p className="py-2 ps-2">Credits</p>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </DivWrapper>
            </>
          ) : (
            ""
          )}

          {/* Invoices */}
          {invoiceAccess &&
          invoiceAccess?.permissions?.find((o) => o.slug === "read") ? (
            <Tooltip title="Invoices" placement="right">
              <LinkWrapper to="/invoices" className="links" onClick={closeMenu}>
                <FaInvoice />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Credit History */}
          {creditAccess &&
          creditAccess?.permissions?.find((o) => o.slug === "read") ? (
            <Tooltip title="Credit History" placement="right">
              <LinkWrapper
                to="/credit-history"
                className="links"
                onClick={closeMenu}
              >
                <FaCreditHistoryIcon />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {customNotifications &&
            customNotifications?.permissions?.find(
              (o) => o.slug === "read"
            ) && (
              <Tooltip title="Custom Notifications" placement="right">
                <LinkWrapper
                  to="/custom-notifications"
                  className="links"
                  onClick={closeMenu}
                >
                  <FaCustomNotification />
                </LinkWrapper>
              </Tooltip>
            )}

          {/* Messenger */}
          {chats && chats?.permissions?.find((o) => o.slug === "read") && (
            <Tooltip title="Messenger" placement="right">
              <LinkWrapper
                to="/messenger"
                className="links"
                onClick={closeMenu}
              >
                <FaMessanger />
              </LinkWrapper>
            </Tooltip>
          )}

          {/* {chatLimiter &&
            chatLimiter?.permissions?.find((o) => o.slug === "read") && (
              <Tooltip title="Chat Burst Activity Dashboard" placement="right">
                <LinkWrapper
                  to="/chat-burst-activity-dashboard"
                  className="links"
                  onClick={closeMenu}
                >
                  <FaChatLogo />
                </LinkWrapper>
              </Tooltip>
            )} */}

          {/* Contact us */}
          {contactUs &&
            contactUs?.permissions?.find((o) => o.slug === "read") && (
              <Tooltip title="Contact Us" placement="right">
                <LinkWrapper
                  to="/contacts"
                  className="links"
                  onClick={closeMenu}
                >
                  <FaContact />
                </LinkWrapper>
              </Tooltip>
            )}

          {/* Vehicles Management */}
          {vehicleManagementAccess &&
          vehicleManagementAccess?.permissions?.find(
            (o) => o.slug === "read"
          ) ? (
            <Tooltip title="Vehicles Management" placement="right">
              <LinkWrapper
                to="/vehicles-management"
                className="links"
                onClick={closeMenu}
              >
                <FaEditVehicle />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Translation */}
          {tanslationAccess &&
          tanslationAccess?.permissions?.find((o) => o.slug === "read") ? (
            <Tooltip title="Translation" placement="right">
              <LinkWrapper
                to="/translation"
                className="links"
                onClick={() => {
                  localStorage.setItem("Translation", "Languages");
                  closeMenu();
                }}
              >
                <FaTranslate />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Partners */}
          {partnerAccess &&
          partnerAccess?.permissions?.find((o) => o.slug === "read") ? (
            <Tooltip title="Partners" placement="right">
              <LinkWrapper to="/partners" className="links" onClick={closeMenu}>
                <FaPartnersIcon />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* <Tooltip title="Import" placement="right">
            <LinkWrapper to="/import-files" className="links" onClick={closeMenu}>
              <FaImport />
            </LinkWrapper>
          </Tooltip> */}

          {/* Legal Documents */}
          {legalDocumentsAccess &&
          legalDocumentsAccess?.permissions?.find((o) => o.slug === "read") ? (
            <DivWrapper className="footerLinks" id="termsBtn">
              <Tooltip title="Legal Documents" placement="right">
                <FaLegal
                  onClick={() => {
                    props._termsMenu(!props.termsMenu);
                    props._priceMenu(false);
                    props._auctionMenu(false);
                    props._profileMenu(false);
                    props._forumMenu(false);
                    props._settingMenu(false);
                    props._hotdealsMenu(false);
                  }}
                />
              </Tooltip>
              {props.termsMenu ? (
                <div className="termsMenu" id="termsMenu">
                  <NavLink
                    to="/legal-documents/nomad-terms"
                    className="auctionLinks"
                    onClick={closeMenu}
                  >
                    <p className="py-2 ps-2 auctionEvent">Nomad Terms</p>
                  </NavLink>
                  <NavLink
                    to="/legal-documents/terms"
                    className="auctionLinks"
                    onClick={closeMenu}
                  >
                    <p className="py-2 ps-2 auctionEvent">Terms</p>
                  </NavLink>
                  <NavLink
                    to="/legal-documents/privacy-policy"
                    className="auctionLinks"
                    onClick={closeMenu}
                  >
                    <p className="py-2 ps-2 auctionEvent">Privacy Policy</p>
                  </NavLink>
                  <NavLink
                    to="/legal-documents/data-protection"
                    className="auctionLinks"
                    onClick={closeMenu}
                  >
                    <p className="py-2 ps-2 auctionEvent">Data Protection</p>
                  </NavLink>
                  <NavLink
                    to="/legal-documents/legal-notice"
                    className="auctionLinks"
                    onClick={closeMenu}
                  >
                    <p className="py-2 ps-2 auctionEvent">Legal Notice</p>
                  </NavLink>
                  <NavLink
                    to="/legal-documents/legal-documents"
                    className="auctionLinks"
                    onClick={closeMenu}
                  >
                    <p className="py-2 ps-2">Legal Documents</p>
                  </NavLink>
                </div>
              ) : (
                ""
              )}
            </DivWrapper>
          ) : (
            ""
          )}

          {/* Flagged Review */}
          <Tooltip title="Flagged Review" placement="right">
            <LinkWrapper
              to="/flagged-review"
              className="links"
              onClick={closeMenu}
            >
              <img src={flagIcon} alt="" role="button" />
            </LinkWrapper>
          </Tooltip>

          {/* Data Provider */}
          {importedDealer &&
            importedDealer?.permissions?.find((o) => o.slug === "read") && (
              <Tooltip title="Data Provider" placement="right">
                <LinkWrapper
                  to="/data-provider"
                  className="links"
                  onClick={closeMenu}
                >
                  <img src={dataProvider} alt="" role="button" />
                </LinkWrapper>
              </Tooltip>
            )}

          {/* Settings */}
          <Tooltip title="Settings" placement="right">
            <DivWrapper className="footerLinks" id="settingBtn">
              <FaGeneralSettingIcon
                onClick={() => {
                  props._settingMenu(!props.settingMenu);
                  props._forumMenu(false);
                  props._hotdealsMenu(false);
                  props._auctionMenu(false);
                  props._termsMenu(false);
                  props._profileMenu(false);
                  props._priceMenu(false);
                }}
              />
            </DivWrapper>
          </Tooltip>
          {props.settingMenu ? (
            <div className="settingMenu" id="settingMenu">
              <NavLink
                to="/general-settings"
                className="auctionLinks"
                onClick={closeMenu}
              >
                <p className="py-2 ps-2 auctionEvent">General settings</p>
              </NavLink>
              {slideAccess &&
              slideAccess?.permissions?.find((o) => o.slug === "read") ? (
                <NavLink
                  to="/slides/home-slider"
                  className="auctionLinks"
                  onClick={closeMenu}
                >
                  <p className="py-2 ps-2 auctionEvent">Home Slider</p>
                </NavLink>
              ) : null}
              {faqAccess &&
              faqAccess?.permissions?.find((o) => o.slug === "read") ? (
                <NavLink
                  to="/faqs"
                  className="auctionLinks"
                  onClick={closeMenu}
                >
                  <p className="py-2 ps-2 auctionEvent">FAQs</p>
                </NavLink>
              ) : null}
              {featuresAccess &&
              featuresAccess?.permissions?.find((o) => o.slug === "read") ? (
                <NavLink
                  to="/features"
                  className="auctionLinks"
                  onClick={closeMenu}
                >
                  <p className="py-2 ps-2 auctionEvent">Features</p>
                </NavLink>
              ) : null}
              {roleAccess &&
              roleAccess?.permissions?.find((o) => o.slug === "read") ? (
                <NavLink
                  to="/admin-roles-management"
                  className="auctionLinks"
                  onClick={closeMenu}
                >
                  <p className="py-2 ps-2 auctionEvent">Roles management</p>
                </NavLink>
              ) : null}
              {commissionAccess &&
              commissionAccess?.permissions?.find((o) => o.slug === "read") ? (
                <NavLink
                  to="/commission"
                  className="auctionLinks"
                  onClick={closeMenu}
                >
                  <p className="py-2 ps-2">Commission</p>
                </NavLink>
              ) : null}
            </div>
          ) : (
            ""
          )}

          {/* Forum  */}
          {ForumAccess &&
          ForumAccess?.permissions?.find((o) => o.slug === "read") ? (
            <Tooltip title="Forums" placement="right">
              <DivWrapper className="footerLinks">
                <FaForumTagsIcon
                  onClick={() => {
                    props._forumMenu(!props.forumMenu);
                    props._settingMenu(false);
                    props._hotdealsMenu(false);
                    props._auctionMenu(false);
                    props._termsMenu(false);
                    props._profileMenu(false);
                    props._priceMenu(false);
                  }}
                />
              </DivWrapper>
            </Tooltip>
          ) : (
            ""
          )}
          {props.forumMenu ? (
            <div className="forumMenu">
              <NavLink to="/tags" className="auctionLinks" onClick={closeMenu}>
                <p className="py-2 ps-2 auctionEvent">Tags</p>
              </NavLink>
              <NavLink
                to="/forum-flags"
                className="auctionLinks"
                onClick={closeMenu}
              >
                <p className="py-2 ps-2">Flagged comments</p>
              </NavLink>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="footerContainer">
        {/* Notifications */}
        <NotificationDrawer />

        {/* profileMenu */}
        <DivWrapper className="footerLinks profileImage">
          <img
            src={userDP !== null && userDP !== "" ? userDP : profilePic}
            onClick={() => {
              props._profileMenu(!props.profileMenu);
              props._termsMenu(false);
              props._priceMenu(false);
              props._auctionMenu(false);
              props._settingMenu(false);
              props._hotdealsMenu(false);
            }}
          />
        </DivWrapper>
        {props.profileMenu ? (
          <div
            className="profileMenu"
            style={
              logAccess &&
              logAccess?.permissions?.find((o) => o.slug === "read")
                ? { bottom: "30px" }
                : { bottom: "45px" }
            }
            onClick={closeMenu}
          >
            <p className="py-2 ps-2 auctionEvent">
              {user.charAt(0).toUpperCase() + user.slice(1)}
            </p>
            <NavLink
              to="/change-password"
              className="auctionLinks"
              onClick={closeMenu}
            >
              <p
                className={
                  (logAccess &&
                  logAccess?.permissions?.find((o) => o.slug === "read")
                    ? "auctionEvent"
                    : "") + " py-2 ps-2"
                }
              >
                Reset Password
              </p>
            </NavLink>
            {logAccess &&
            logAccess?.permissions?.find((o) => o.slug === "read") ? (
              <NavLink
                to="/server-logs"
                className="auctionLinks"
                onClick={closeMenu}
              >
                <p className="py-2 ps-2">Server Logs</p>
              </NavLink>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {/* Logout */}
        <Tooltip title="Logout" placement="right">
          <DivWrapper className="footerLinks" onClick={logout}>
            <FaLogout />
          </DivWrapper>
        </Tooltip>
      </div>
    </div>
  );
};

export default Menu;

const LinkWrapper = styled(NavLink)`
  svg {
    width: 25px;
    height: 25px;
    fill: #ffffff;
    path {
      // stroke: #FFFFFF;
    }
  }
`;

const DivWrapper = styled.div`
  svg {
    width: 25px;
    height: 25px;
    fill: #ffffff;
    path {
      stroke: #ffffff;
    }
    cursor: pointer;
  }
`;
