import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { APP_URLS } from "../../api/url";
import API from "../../api/axios";
import { toast } from "react-toastify";
import {
  SITE_SETTING_KEY_NAMES,
  siteSettingTabs,
  siteSettingTabsObj,
} from "../../config/constant";
import { GeneralSettingsWrapper } from "./style/GeneralSettingStyle";
import back from "../../assets/Images/icons/svgIcon/back-arrow.svg";
import { useNavigate } from "react-router-dom";
import info from "../../assets/Images/image/info-icon.png";
import { ThreeDots } from "react-loader-spinner";
import { confirmAlert } from "react-confirm-alert";

const GeneralSettings = () => {
  const [loading, _loading] = useState(false);
  const [loadingPage, _loadingPage] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [currentTabFields, setCurrentTabFields] = useState([]);

  const navigate = useNavigate();
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const chatLimiter = permissions.find((o) => o.slug === "chat_messages");
  const havingReadPermissions =
    (chatLimiter && chatLimiter?.permissions.find((o) => o.slug === "read")) ??
    false;

  useEffect(() => {
    //getting site settings tabs
    const getAllTabs = () => {
      _loadingPage(true);
      API.get(`${APP_URLS.GENERAL_SETTINGS}?filter[type]=${currentTab}`)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            const fields = resp.data.items[currentTab];
            setFields(fields ?? []);
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            if (resp.data.data.message) {
              error_message = resp.data.data.message;
            } else {
              {
                Object.keys(resp.data.data).map(
                  (error, index) => (error_message = resp.data.data[error][0])
                );
              }
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .finally(() => {
          _loadingPage(false);
        });
    };
    getAllTabs();
  }, [currentTab]);

  const setFields = (fields) => {
    if (currentTab === siteSettingTabsObj.chatSettings) {
      setCurrentTabFields(
        fields.sort((a, b) => {
          const aIsNonCertified = a.key_name.includes("non_certified_users")
            ? -1
            : 1;
          const bIsNonCertified = b.key_name.includes("non_certified_users")
            ? -1
            : 1;
          return aIsNonCertified - bIsNonCertified;
        })
      );
      return;
    }
    setCurrentTabFields(fields);
  };

  // Construct initial values object dynamically
  const initialValues = currentTabFields?.reduce((acc, field, index) => {
    acc[field.key_name] = field.value || ""; // Set default value if available, otherwise an empty string
    return acc;
  }, {});

  // Construct validation schema dynamically
  const validationSchema = Yup.object().shape(
    currentTabFields?.reduce((acc, field) => {
      // Add conditional validation based on key name
      if (field.key_name === SITE_SETTING_KEY_NAMES.PURCHASE_BLOCK_TIME) {
        acc[field.key_name] = Yup.string()
          .required("Purchase block time is required")
          .matches(
            /^(0*[1-9]|[1-2]\d|30)$/,
            "Purchase block time must be a number less than or equal to 30"
          );
      } else if (field.key_name === SITE_SETTING_KEY_NAMES.VIDEO_URL) {
        acc[field.key_name] = Yup.string()
          .required("Video URL is required")
          .url("Invalid URL format");
      } else if (
        field.key_name ===
          SITE_SETTING_KEY_NAMES.HOTDEALS_NOTIFICATION_DAYS_SOLD_NO_ACTION ||
        field.key_name ===
          SITE_SETTING_KEY_NAMES.HOTDEALS_PAYMENT_RELEASE_DELAY_DAYS
      ) {
        acc[field.key_name] = Yup.string()
          .required("Field is required")
          .matches(
            /^(0*[1-9]|[1-2]\d|30)$/,
            "Field must be a number less than or equal to 30"
          );
      } else if (field.key_name === SITE_SETTING_KEY_NAMES.MAINTENANCE_MODE) {
        acc[field.key_name] = Yup.boolean();
      } else {
        acc[field.key_name] = Yup.string().required();
      }
      return acc;
    }, {})
  );

  //handling form initialvalues, validations and submition
  const formik = useFormik({
    initialValues,
    enableReinitialize: true, //to reinitialze the initial values
    validationSchema,
    onSubmit: (values) => {
      if (currentTab === siteSettingTabsObj.chatSettings) {
        const minuteLimitforCertifiedUsers =
          parseInt(values["per_ten_minute_chat_limit_for_certified_users"]) ??
          0;
        const hourlyLimitforCertifiedUsers =
          parseInt(values["hourly_chat_limit_for_certified_users"]) ?? 0;
        const minuteLimitforNotCertifiedUsers =
          parseInt(
            values["per_ten_minute_chat_limit_for_non_certified_users"]
          ) ?? 0;
        const hourlyLimitforNotCertifiedUsers =
          parseInt(values["hourly_chat_limit_for_non_certified_users"]) ?? 0;
        if (
          minuteLimitforCertifiedUsers > hourlyLimitforCertifiedUsers ||
          minuteLimitforNotCertifiedUsers > hourlyLimitforNotCertifiedUsers
        ) {
          toast.error(
            "The per 10-minute chat limit cannot be greater than the hourly chat limit. Please adjust the values accordingly.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          return;
        }
      }
      //checking if form has any changes or not
      const isChanged = currentTabFields?.some(
        (key) =>
          formik.initialValues[key.key_name] !== formik.values[key.key_name]
      );
      if (!loading && isChanged) {
        if (currentTab === siteSettingTabsObj.miscellaneous) {
          confirmAlert({
            title: "Confirmation required",
            message:
              "Are you sure you want to perform this action on CartoBike?",
            closeOnEscape: true,
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  if (!loading && isChanged) {
                    _loading(true);
                    const formValues = {
                      type: currentTab,
                      items: currentTabFields?.map((field) => ({
                        key_name: field.key_name,
                        value: formik.values[field.key_name],
                      })),
                    };
                    getGeneralSettingFields(formValues);
                  }
                },
              },
              {
                label: "No",
              },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            overlayClassName: "overlay-custom-class-name",
          });
          return;
        }

        _loading(true);
        const formValues = {
          type: currentTab,
          items: currentTabFields?.map((field) => ({
            key_name: field.key_name,
            value: formik.values[field.key_name],
          })),
        };
        getGeneralSettingFields(formValues);
      }
    },
  });

  function getGeneralSettingFields(formValues) {
    // Show loading toast
    const toastId = toast.loading("Saving changes...", {
      position: toast.POSITION.TOP_RIGHT,
    });
    API.post(APP_URLS.GENERAL_SETTINGS, formValues)
      .then((res) => {
        const resp = res.data;
        _loading(false);
        if (resp.success === true) {
          setFields(resp?.data?.items ?? []);
          //Update loading toast to success
          toast.update(toastId, {
            render: "Your changes have been saved successfully",
            type: "success",
            isLoading: false,
            autoClose: 5000,
          });
        } else {
          // toast.error(resp.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        _loading(false);
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          if (resp.data.data.message) {
            error_message = resp.data.data.message;
          } else {
            {
              Object.keys(resp.data.data).map(
                (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        //Update loading toast to error
        toast.update(toastId, {
          render: error_message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  }

  // Function to format the key name(removing underscore and adding needed texts) and generate the tooltip title
  function formatKeyDetails(keyName) {
    let fieldName = keyName
      .replace(/_/g, " ")
      .replace(/^\w/, (firstChar) => firstChar.toUpperCase());
    let tooltipTitle = "";
    let note = "";

    switch (keyName) {
      case SITE_SETTING_KEY_NAMES.VIDEO_URL:
        tooltipTitle = "This is the video url used on the hotdeal page.";
        note = "NOTE:- Url must be a embed url";
        break;

      case SITE_SETTING_KEY_NAMES.PURCHASE_BLOCK_TIME:
        fieldName += " (Minutes)";
        tooltipTitle =
          "This is the time in minutes that specifies when one user is making a payment; it determines the block time for vehicle purchases for the other user.";
        break;

      case SITE_SETTING_KEY_NAMES.HOTDEALS_NOTIFICATION_DAYS_SOLD_NO_ACTION:
        fieldName =
          "Notify admin after days without buyer action on hotdeal vehicles";
        tooltipTitle =
          "Send notification to the admin regarding hotdeal vehicles that have been sold by the seller, but for which the buyer has not taken any action (i.e., neither accepted nor rejected the vehicle).";
        break;

      case SITE_SETTING_KEY_NAMES.HOTDEALS_PAYMENT_RELEASE_DELAY_DAYS:
        fieldName = "Payment release delay (Days) for hotdeal car sales";
        tooltipTitle =
          "The number of days after which payment will be released to the seller from CarToBike.";
        break;

      case SITE_SETTING_KEY_NAMES.HOTDEALS_BUYER_CONFIRMATION_DAYS:
        tooltipTitle =
          "This is the number of days the buyer has to accept or reject the vehicle after the seller marks the vehicle as sold.";
        break;

      case SITE_SETTING_KEY_NAMES.MAINTENANCE_MODE:
        tooltipTitle =
          "By enabling this cartobike site will go into maintenance mode";
        break;

      default:
        tooltipTitle = fieldName;
        break;
    }

    return { fieldName, tooltipTitle, note };
  }

  const getError = (err, key, fieldName) => {
    let error = err.replace(key, fieldName);
    return error;
  };
  const allowedCheckboxFields = useMemo(
    () =>
      new Set([
        "maintenance_mode",
        "allow_classic_ad_posting_unverified_users",
        "allow_auction_ad_posting_unverified_users",
      ]),
    []
  );

  return (
    <GeneralSettingsWrapper>
      <div className="general-settings">
        <div className="row mt-4">
          <div className="col-md-4 d-flex align-items-center">
            <img
              src={back}
              className="ms-4 backbtn"
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="col-md-4">
            <div className="heading">
              <h1 className="text-uppercase">General Settings</h1>
            </div>
          </div>
        </div>

        <form className="check" onSubmit={formik.handleSubmit}>
          <div className="py-4 d-flex justify-content-between">
            <div className="d-flex gap-2">
              {siteSettingTabs.map((tab) => {
                if (
                  tab.type === siteSettingTabsObj.chatSettings &&
                  !havingReadPermissions
                ) {
                  return null;
                }
                return (
                  <div
                    key={tab.type}
                    className={`btn ${
                      currentTab === tab.type ? "active-tab" : ""
                    }`}
                    onClick={() => setCurrentTab(tab.type)}
                  >
                    {tab.name}
                  </div>
                );
              })}
            </div>
            {!loadingPage && (
              <button
                type="submit"
                className="save-btn px-4 d-flex align-items-center justify-content-center rounded-3"
              >
                Save
              </button>
            )}
          </div>
          {loadingPage ? (
            <div className="d-flex justify-content-center three-dot">
              <ThreeDots
                height="150"
                width="150"
                radius="9"
                color="#637df4"
                ariaLabel="three-dots-loading"
                visible={true}
              />
            </div>
          ) : (
            <div className="d-flex flex-column gap-4 py-4">
              {currentTabFields?.length > 0
                ? currentTabFields.map((field, index) => {
                    // Using the function to get both the formatted key name and tooltip title
                    const { fieldName, tooltipTitle, note } = formatKeyDetails(
                      field.key_name
                    );
                    const isCheckbox = allowedCheckboxFields.has(
                      field.key_name
                    );

                    return (
                      <div className="row" key={field.id}>
                        {currentTab === siteSettingTabsObj.chatSettings && (
                          <h3 style={{ marginBottom: "10px" }}>
                            {index === 0 && "For non certified users"}

                            {currentTabFields[index - 1]?.key_name.includes(
                              "non_certified_users"
                            ) &&
                              !field?.key_name.includes(
                                "non_certified_users"
                              ) &&
                              "For certified users"}
                          </h3>
                        )}

                        <div className="col-md-2 fieldText d-flex align-items-center">
                          <h5>
                            {fieldName}
                            {currentTab !== siteSettingTabsObj.chatSettings && (
                              <span title={tooltipTitle}>
                                <img src={info} className="ms-1 backbtn" />
                              </span>
                            )}
                          </h5>
                        </div>
                        <div className="col-md-3">
                          {isCheckbox ? (
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={field.key_name}
                              name={field.key_name}
                              checked={Boolean(
                                Number(formik.values[field.key_name])
                              )} // Assuming "1" is the true value
                              onChange={(event) => {
                                const newValue = event.target.checked
                                  ? "1"
                                  : "0"; // Convert to string for consistency
                                formik.setFieldValue(field.key_name, newValue);
                              }}
                            />
                          ) : (
                            <input
                              type="text"
                              id={field.key_name}
                              name={field.key_name}
                              className="form-control"
                              value={
                                formik.dirty
                                  ? formik.values[field.key_name]
                                  : field.value
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          )}
                          <span>{note}</span>
                          {formik.touched[field.key_name] &&
                          formik.errors[field.key_name] ? (
                            <div className="error">
                              {getError(
                                formik.errors[field.key_name],
                                field.key_name,
                                fieldName
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          )}
        </form>
        <div></div>
      </div>
    </GeneralSettingsWrapper>
  );
};
export default GeneralSettings;
