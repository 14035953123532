export const PRODUCT_TYPES = {
  1: "Car",
  4: "Bike",
  7: "E-scooter",
};

export const TYPE_OF_PROFILE = ["All", "Dealer", "Private"];

export const RECURRING = ["One Time", "Weekly", "Monthly", "Yearly", "daily"];

export const AUCTION_STATUS = {
  in_progress: "active-status",
  upcoming: "progress-status",
  in_registration: "registration-status",
  ended: "end-status",
  registration_ended: "registration-end-status",
};

export const DEBOUNCE_TIME = 500;
export const COMMISSION_RECURRING = ["", "Monthly", "Quarterly", "Yearly"];

export const USER_ACTIVITY_LOG_TYPE = ["", "Created", "Updated", "Deleted"];

export const BID_ACCEPTED = ["status-pending", "status-active", "status-end"];

export const USER_ACTIVE_STATUS = ["Inactive", "Active"];

export const NOMAD_USER_VERIFICATION_STATUS = ["", "Applied", "Verified"];

export const FRONT_OFFICE_USER_VERIFICATION_STATUS = [
  "Not Applied",
  "Applied",
  "Verified",
];

export const SUBSIDIARY = {
  active: "active-status",
  canceled: "registration-end-status",
  incomplete: "end-status",
  past_due: "progress-status",
  incomplete_expired: "end-status",
  unpaid: "progress-status",
};

export const INVOICE_STATUS = {
  paid: "active-status",
  void: "end-status",
  open: "progress-status",
};

export const CENSORED_DURATION = [
  "1 Week",
  "2 Weeks",
  "1 Month",
  "3 Months",
  "1 Day",
  "3 Days",
  "Forever",
];

export const BANNED = [
  "Front Office",
  "Front Office",
  "Front Office",
  "",
  "Nomad",
  "Forum",
];

export const MODULES_DISABLED = {
  0: "App Access",
  1: "Ads",
  2: "Bids",
  4: "App Access",
  5: "Forum",
};

export const APP_TYPE = {
  ADMIN_PANEL: 1,
  FRONT_OFFICE: 2,
  BACK_OFFICE: 3,
};

export const APP_TYPE_INVERSE = {
  1: "Admin Panel",
  2: "Front Office",
  3: "Nomad",
};

export const NOMAD_VALIDATION_TYPE_LIST = [
  "VAT",
  "Dealer Name",
  "Address",
  "Nomad Document",
];

export const WEEKDAYS = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6,
};

export const PARTNERS = {
  1: "Salutation",
  2: "Subject",
};

export const FLAGGED_REVIEW_STATUS = {
  All: 1,
  Active: 2,
  Deleted: 3,
  "No action": 4,
};

export const MAPPED_VALUES = {
  "Non-Assigned": 0,
  Assigned: 1,
};

export const DATA_PROVIDER_ERROR_FILTER = {
  Retry: 1,
  Error: 2,
};

export const ASPECT_RATIOS = {
  HOME: 3 / 1,
  PARTNER: 4 / 3,
};

export const ADD_SLIDER_TYPE = {
  HOME: 2,
  PARTNER: 1,
};
export const PROVIDERS = [
  { value: 1, name: "MODIX" },
  { value: 2, name: "CARDIFF" },
  { value: 3, name: "UBIFLOW" },
  { value: 4, name: "Volkswagen" },
  { value: 5, name: "Autoscout" },
  { value: 6, name: "Fastback" },
];

export const ADVERTISERS_PROVIDERS = [
  { id: 3, name: "UBIFLOW" },
  { id: 5, name: "Autoscout" },
];

export const modelType = {
  brand: 8,
};

export const verificationIdTypes = {
  drivingLicense: 1,
  passport: 2,
  selfie: 3,
  nationalId: 4,
};

export const verificationIdsCollectionName = {
  nationalIdFront: "national_id_front",
  nationalIdBack: "national_id_back",
  drivingLicenseFront: "driving_license_front",
  drivingLicenseBack: "driving_license_back",
  selfieWithId: "selfie_with_id_document",
  passport: "passport",
};

export const ProductTypes = {
  car: 1,
  bike: 4,
  scooter: 7,
};

export const userTypes = {
  Dealer: 1,
  Private: 2,
};

export const auctionUserTypes = {
  All: 0,
  Dealer: 1,
  Private: 2,
};

export const frequencies = {
  "One Time": 0,
  Weekly: 1,
  Monthly: 2,
  Yearly: 3,
};

export const auctionStatus = {
  "In progress": "in_progress",
  Upcoming: "upcoming",
  "In Registration": "in_registration",
  Ended: "ended",
  "Registration Ended": "registration_ended",
};

export const adsValidStatus = {
  "Only Unvalidated": 0,
  "Only Validated": 1,
};

export const adsOnlineStatus = {
  Offline: 0,
  Online: 1,
};

export const bidPercents = {
  "35 %": "35",
  "50 %": "50",
  "70 %": "70",
};

export const AccountCreationDates = {
  Yesterday: "1",
  "Last 3 Days": "2",
  "Last 1 Week": "3",
  "Current Month": "4",
  "Previous Month": "5",
  "Custom (To and From)": "6",
};

export const AccountCreationDatesType = {
  YESTERDAY: "1",
  LAST_3_DAYS: "2",
  LAST_1_WEEK: "3",
  CURRENT_MONTH: "4",
  PREVIOUS_MONTH: "5",
  CUSTOM_TO_AND_FROM: "6",
};

export const ProfileCompletionStatus = {
  Complete: "1",
  Incomplete: "0",
};

export const verifiedProfileStatus = {
  Active: "1",
  Inactive: "0",
};

export const verificationStatus = {
  "Not Applied": "0",
  Applied: "1",
  Verified: "2",
};

export const productStatusOptions = {
  Active: "active",
  Canceled: "canceled",
  Cancelling: "cancelling",
  Incomplete: "incomplete",
  "Past Due": "past_due",
  "Incomplete Expired": "incomplete_expired",
};

export const userRoleOptions = {
  Admin: "1",
  Nomad: "3",
};

export const couponTypeOptions = {
  Subscriptions: 1,
  Credits: 2,
};

export const validationOptions = {
  Valid: "1",
  Invalid: "0",
};

export const subscriptionOptions = {
  Forever: "forever",
  Once: "once",
  Repeating: "repeating",
};

export const paymentStatusOptions = {
  Paid: "2",
  Pending: "1",
};

export const supportedExtensionForIcons = {
  web: ["svg"],
  mobile: ["webp", "png"],
};

export const userTypeName = {
  DEALER: "Dealer",
  PRIVATE: "Private",
  DEALER_CAPITAL: "DEALER",
};

export const couponTypeName = {
  SUBSCRIPTIONS: "Subscriptions",
  CREDITS: "Credits",
};

export const planType = {
  RECURRING: "recurring",
  ONE_TIME: "one_time",
};

export const siteSettingTabs = [
  { type: 1, name: "Hotdeals" },
  { type: 2, name: "Miscellaneous" },
  // { type: 3, name: "Chat limit settings" },
];

export const siteSettingTabsObj = {
  hotdeal: 1,
  miscellaneous: 2,
  // chatSettings: 3,
};

export const SITE_SETTING_KEY_NAMES = {
  PURCHASE_BLOCK_TIME: "purchase_block_time",
  VIDEO_URL: "video_url",
  HOTDEALS_NOTIFICATION_DAYS_SOLD_NO_ACTION:
    "hotdeals_notification_days_sold_no_action",
  HOTDEALS_PAYMENT_RELEASE_DELAY_DAYS: "hotdeals_payment_release_delay_days",
  HOTDEALS_BUYER_CONFIRMATION_DAYS: "hotdeals_buyer_confirmation_days",
  MAINTENANCE_MODE: "maintenance_mode",
};

export const recurringFrequencyValue = {
  WEEKLY: "1",
  MONTHLY: "2",
  YEARLY: "3",
  DAILY: "4",
};

export const commissionTypeOptions = {
  "Nomad commission": "1",
  "Vehicle sale commission": "2",
};

export const userConfig = {
  user: {
    field: "user_id",
  },
  subsidiary: {
    field: "subsidiary_id",
  },
};

export const creditTypes = {
  PURCHASED: 1,
  USED: 2,
  EXPIRED: 3,
  ADDED_BY_ADMIN: 4,
  REFUNDED: 5,
  LICENSE_SEARCH: 6,
  REMOVED_BY_ADMIN: 7,
};

export const suggestionText = {
  VERIFICATION_REJECT: 0,
  USER_CENSORSHIP: 1,
  NOMAD_PROFILE: 2,
  SUBSIDIARY_CENSORSHIP: 3,
  AD_DELETED: 4,
  ADD_CREDIT: 5,
  FORUM_CENSORSHIP: 6,
};

export const requestType = {
  SELLER: 1,
  BUYER: 2,
};

export const approvalStatus = {
  ACCEPT: 1,
  REJECT: 2,
};

export const penaltyType = {
  WITH_PENALTY: 1,
  WITHOUT_PENALTY: 2,
};

export const userTypeTypo = {
  USER: "user",
  SUBSIDIARY: "subsidiary",
};

export const forumFlagModelType = {
  QUESTION: "question",
  ANSWER: "answer",
};

export const forumFlagModelTypeOptions = {
  All: "",
  Question: "question",
  Answer: "answer",
};

export const actionTypeOptions = {
  All: "",
  Approved: 1,
  Rejected: 2,
  "Pending actions": 3,
};

export const refundRequestTypeOptions = {
  All: "",
  "Pre sale": 1,
  "Post sale": 2,
};

export const refundRequestTypeText = {
  PRE_SALE: "Pre sale",
  POST_SALE: "Post sale",
};

export const refundRequestType = {
  PRE_SALE: 1,
  POST_SALE: 2,
};

export const USER_TYPE = {
  ADMIN_PANEL: "1",
  FRONT_OFFICE: "2",
  BACK_OFFICE: "3", // NOMAD
  FORUM: "4",
};

export const USER_TYPE_TEXT = {
  ADMIN_PANEL: "adminPanel",
  FRONT_OFFICE: "frontOffice",
  BACK_OFFICE: "backOffice", // NOMAD
  FORUM: "forum",
};

export const notificationType = {
  IMPORTED_DEALER: "imported_dealer",
  FLAGGED_RATING: "flagged_rating",
  PARTNER: "partner",
  INVITATION: "invitation",
  CONTACT_US: "contact_us",
  HOTDEAL: "hotdeal",
};

export const refundStatus = {
  APPLIED: 1,
  NOT_APPLIED: 2,
};

export const penaltyText = {
  1: "with penalty",
  2: "without penalty",
};

export const PAYMENT_TYPE = {
  CREDIT_BUY: 1,
  VEHICLE_BUY: 2,
  SUBSCRIPTION: 3,
};

export const invoiceTabs = {
  SUBSCRIPTION: "subscription",
  CREDIT: "credit",
  COMMISSION: "commission",
  NOMAD: "nomad",
};

export const refundOption = {
  FULL_REFUND: 1,
  CUSTOME_REFUND: 2,
};

export const vehicleStatus = {
  SELLER_NO_ACTION: { id: 1, label: "Seller no action" },
  BUYER_NO_ACTION: { id: 2, label: "Buyer no action" },
  SELLER_CANCEL_RESERVATION_REQUEST: {
    id: 3,
    label: "Seller cancel reservation request",
  },
  BUYER_CANCEL_RESERVATION_REQUEST: {
    id: 4,
    label: "Buyer cancel reservation request",
  },
  CANCEL_RESERVATION_REQUEST_ACCEPTED: {
    id: 5,
    label: "Cancel reservation request accepted",
  },
  SALE_COMPLETED: { id: 6, label: "Sale completed" },
};

export const paymentStatus = {
  ESCROW: { id: 1, label: "Escrow" },
  PAID: { id: 2, label: "Paid" },
  REFUND_INITIATED: { id: 3, label: "Refund initiated" },
  AMOUNT_REFUNDED: { id: 4, label: "Amount refunded" },
  AMOUNT_NOT_REFUNDED: { id: 5, label: "Amount not refunded" },
};

export const pendingAction = {
  SELLER_ACTION_PENDING: { id: 1, label: "Seller action pending" },
  BUYER_ACTION_PENDING: { id: 2, label: "Buyer action pending" },
  ALL_PENDING_RECORDS: { id: 3, label: "All pending records" },
  VEHICLE_ACTION_REQUIRED_15_DAYS_PASSED: {
    id: 4,
    label: "Vehicle action required 15 days passed",
  },
};

export const visitorsMethodsTypes = {
  view: 1,
  call: 2,
  chat: 3,
};

export const editNameAndAddressSteps = {
  userName: 1,
  userAddress: 2,
};

export const creditExpensType = {
  PURCHASED: 1,
  USED: 2,
  EXPIRED: 3,
  ADDED: 4,
  REFUNDED: 5,
  LICENSESEARCH: 6,
};

export const hotdealDiscountType = {
  applied: 1,
  notApplied: 0,
};

export const screenNames = [
  { value: "ClassicAd", name: "Classic ad list" },
  { value: "ContactUs", name: "Contact us" },
  { value: "CreateSubsidiary", name: "Company list" },
  { value: "CreditPlansScreen", name: "Credit plan screen" },
  { value: "CreditScreen", name: "Wallet screen" },
  { value: "DashboardStackNavigator", name: "Home page" },
  { value: "EditUserProfile", name: "Edit profile" },
  { value: "InvoiceList", name: "Bills" },
  { value: "LicensePlateScreen", name: "License plate" },
  { value: "Myads", name: "My ads" },
  { value: "Park", name: "My orders" },
  { value: "PaymentCards", name: "Payment method" },
  { value: "UserSetting", name: "Settings" },
  { value: "VerifyIdScreen", name: "Verify Id screen" },
];
export const deviceTypes = [
  { value: 1, name: "IOS" },
  { value: 2, name: "Android" },
  { value: 3, name: "Web" },
  { value: 4, name: "All" },
];

export const chatLimits = {
  1: "10-minute",
  2: "hourly",
  3: "daily",
};
